// Connection type
export const connectionPage = '.';

// Cookies type
// export const cookiesRegister = './';
// export const cookiesRegister = 'preview.maxmidiaooh.com.br/';
export const cookiesRegister = 'maxmidiaooh.com.br/';

// Cookies name
export const cookie_site = 'midiaooh_site';
export const cookie_favorite = 'midiaooh_favorite';