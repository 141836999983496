import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { SvgArrow_3 } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import Menu from "components/Menu";

import PopUP_ShowFile from "components/PopUp/ShowFile";
import PopUP_Redirect from "components/PopUp/Redirect";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import Page_Home from "components/Page/Home";
import Page_Panel from "components/Page/Panel";
import Page_Broadcast from "components/Page/Broadcast";
import Model_Contact from "components/ModelPage/Contact";

export default function App() {

    const cookies                 = new Cookies();
    const [ loading, setLoading ] = useState(false);

    const [ page, setPage ]         = useState(GetListPag('page'));
    const [ showData, setShowData ] = useState(GetDataPage('name_menu'));

    const [ url, setUrl ]       = useState(window.location.href.split("/"));
    const [ infUrl, setInfUrl ] = useState(0);

    function ReturnIndex(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    function NamePageUrl(){
        if(url){
            if(GetDataPage('list_favorite').find(item => item.url_page == url[3])){
                SetListPag('page', 'panel');
                        
            }else {
                switch (url[3]) {
                    case "index": case "panel": case "broadcast":
                            SetListPag('page', url[3]);
                        break;
                    default:
                            SetListPag('page', 'index');
                        break;
                }
            }
        }else{
            SetListPag('page', 'index');
        }
    }

    function CkickPage(value){      
        switch (value) {
            case "index": case "panel": case "broadcast":
                    SetListPag('page', value);   
                break;
        
            default: 
                    SetListPag('remuve', 'All');
                    SetListPag('page', 'index');   
                break;
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setPage);

        NamePageUrl();
        
        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('return').classList.add('return_index');
                document.getElementById('return').classList.remove('return_close');
            }else {
                document.getElementById('return').classList.add('return_close');
                document.getElementById('return').classList.remove('return_index');
            }
        });
    }, []);

    useEffect(()=>{
        setPage(GetListPag('page'));
    }, [page]);

    useEffect(()=>{
        if(loading == true){
            document.body.style.overflow = "hidden";
        }else {
            document.body.style.overflow = null;
        }
    }, [loading]);

    return (
        <Router>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                 <div className="hourglassBackground">
                    <div className="hourglassContainer">
                        <div className="hourglassCurves" />
                        <div className="hourglassCapTop" />
                        <div className="hourglassGlassTop" />
                        <div className="hourglassSand" />
                        <div className="hourglassSandStream" />
                        <div className="hourglassCapBottom" />
                        <div className="hourglassGlass" />
                    </div>
                </div>
            </div>
            
            <div className="whatsapp">
                <a href="https://wa.me/+5541991617078" target="_blank">
                    <img alt="Whatsapp" src="./assets/whatsapp.svg" className="icon_whatsapp" />
                </a>
            </div>

            <div className="return_close" id="return" onClick={ ()=>{ ReturnIndex() } }>
                <SvgArrow_3 className="icon_whatsapp" color="rgb(255 181 0)" />
            </div>

            <Menu CkickPage={ CkickPage } setLoading={ setLoading } />

            <Routes>
                <Route exact path="/" element={ <Page_Home ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                <Route exact path="/*" element={ <Page_Home ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />

                <Route exact path="/panel" element={ <Page_Panel ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } listFavorite="" />

                <Route exact path="/broadcast" element={ <Page_Broadcast ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } listFavorite="" />

                {
                    GetDataPage('list_favorite').map((elem, index)=>{
                        return(
                            <Route exact path={ "/" + elem } element={ <Page_Panel ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } listFavorite={ elem } />
                        );
                    })
                }
            </Routes>

            <Model_Contact CkickPage={ CkickPage } setLoading={ setLoading } idPage={ 7 } />

            <PopUP_Redirect />
            <PopUP_ShowFile />
            <PopUP_ReturnResponse />
        </Router>
    );
}