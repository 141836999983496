import React, { useState, useEffect } from "react";

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import './ShowFile.css';

export default function PopUP_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ code, setCode ]                   = useState('');
    const [ premium, setPremium ]               = useState('');
    const [ file, setFile ]                     = useState('');
    const [ dateStart, setDateStart ]           = useState('');
    const [ dateEnd, setDateEnd ]               = useState('');
    const [ typeName, setTypeName ]             = useState('');
    const [ formatName, setFormatName ]         = useState('');
    const [ state, setState ]                   = useState('');
    const [ city, setCity ]                     = useState('');
    const [ neighborhood, setNeighborhood ]     = useState('');
    const [ address, setAddress ]               = useState('');
    const [ latitude, setLatitude ]             = useState('');
    const [ longitude, setLongitude ]           = useState('');
    const [ flowGenerators, setFlowGenerators ] = useState('');
    const [ size, setSize ]                     = useState('');
    const [ streetView, setStreetView ]         = useState('');
    const [ codeVideo, setCodeVideo ]           = useState('');
    const [ text, setText ]                     = useState('');

    function ClosePopUp(){
        SetModalState('ShowFile', false);

        setCode('');
        setPremium('');
        setFile('');
        setDateStart('');
        setDateEnd('');
        setTypeName('');
        setFormatName('');
        setState('');
        setCity('');
        setNeighborhood('');
        setAddress('');
        setLatitude('');
        setLongitude('');
        setFlowGenerators('');
        setSize('');
        setStreetView('');
        setCodeVideo('');
        setText('');
    }

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);
    
    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = "hidden";

            setCode(modalData.data.code);
            setPremium(modalData.data.premium);
            setFile(modalData.data.file);
            setDateStart(modalData.data.date_start);
            setDateEnd(modalData.data.date_end);
            setTypeName(modalData.data.type_name);
            setFormatName(modalData.data.format_name);
            setState(modalData.data.state);
            setCity(modalData.data.city);
            setNeighborhood(modalData.data.neighborhood);
            setAddress(modalData.data.address);
            setLatitude(modalData.data.latitude);
            setLongitude(modalData.data.longitude);
            setFlowGenerators(modalData.data.flow_generators);
            setSize(modalData.data.size);
            setStreetView(modalData.data.street_view);
            setCodeVideo(modalData.data.code_video);
            setText(modalData.data.text);
        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all show_file">
                        <div className="div_data type_div">
                            <div className="title">
                                { state } - { city }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="div_img">
                                    <img alt="img" src={ file } className="img" />
                                    <div className="code">
                                        <div className="">Código: { code }</div>
                                        {
                                            typeName == "" ? null : <div className=""><span>-</span> Tipo: { typeName }</div>
                                        }
                                    </div>
                                </div>
                                <div className="show_data_panel">
                                    <div className="map">
                                        <iframe allowFullScreen="" src={ "https://www.google.com/maps/embed/v1/place?q=" + latitude + "%2C" + longitude + "&key=AIzaSyB1uFK8QwpiyYNPt2SecHgtg384w12sOhc" } />
                                    </div>
                                    <div className="description">
                                        {
                                            address == "" ? null :
                                            <div className="text_line">
                                                <div className="div_inf_name">Endereço</div>
                                                <div className="show_inf_panel">{ address }</div>
                                            </div>
                                        }
                                        {
                                            city == "" ? null :
                                            <div className="text_line">
                                                <div className="div_inf_name">Cidade</div>
                                                <div className="show_inf_panel">{ city }</div>
                                            </div>
                                        }
                                        {
                                            neighborhood == "" ? null :
                                            <div className="text_line">
                                                <div className="div_inf_name">Bairro</div>
                                                <div className="show_inf_panel">{ neighborhood }</div>
                                            </div>
                                        }
                                        {
                                            size == "" ? null :
                                            <div className="text_line">
                                                <div className="div_inf_name">Tamanho</div>
                                                <div className="show_inf_panel">{ size }</div>
                                            </div>
                                        }
                                        {
                                            longitude == "" || latitude == "" ? null :
                                            <div className="text_line">
                                                <div className="div_inf_name">Coordenadas</div>
                                                <div className="show_inf_panel">
                                                    latitude { latitude }, longitude { longitude }
                                                </div>
                                            </div>
                                        }
                                        {
                                            flowGenerators == "" ? null :
                                            <div className="text_line">
                                                <div className="div_inf_name">Geradores de fluxo</div>
                                                <div className="show_inf_panel">{ flowGenerators }</div>
                                            </div>
                                        }
                                        {
                                            streetView == "" ? null :
                                            <div className="text_line btn_street_view">
                                                <a href={ streetView } target="_blank">
                                                    Street View
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
