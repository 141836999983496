import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import App from './App';

import Axios from 'axios';

import { SetListData, SetListDataSingle } from 'interface/Data';

const root = ReactDOM.createRoot(document.getElementById('root'));

const data = new FormData();
data.append('current_page', 'show_data');

Axios({
    url     : process.env.REACT_APP_API_URL + 'php/show_data.json',
    // url     : process.env.REACT_APP_API_URL + 'php/list_data.php',    
    mode    : 'no-cors',
    method  : 'POST',
    data    : data
})
.then(response => {

    SetListData(response.data);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/list_data.php',
        method  : 'POST',
        data    : data
    })
    .then(response => {        
        SetListDataSingle('list_favorite', response.data.list_favorite);

        root.render(
            <React.Fragment>
                <App />
            </React.Fragment>
        );

    }).catch((error)=>{
        root.render(
            <React.Fragment>
                <App />
            </React.Fragment>
        );
    });
}).catch((error)=>{
});
