import { useState, useEffect, useRef } from "react";

import './Home.css';

import { GetDataPage } from "interface/Data";
import { GetListPag } from "interface/Page";

import { SvgArrow_2 } from "components/SvgFile";

export default function Model_Home(props){

    const showVideo = useRef();
    const [ showData, setShowData ] = useState(ShowDataInitial());
    const [ page, setPage ]         = useState(GetListPag('page'));
    
    let countSlide                  = 0;
    const [ qtdCount, setQtdCount ] = useState(6);

    function ShowDataInitial(){
        let newData = [];
        if(GetDataPage('site')[props.index].contents){
            if(GetDataPage('site')[props.index].contents.length == 1){
                newData.push(GetDataPage('site')[props.index].contents[0]);
                newData.push(GetDataPage('site')[props.index].contents[0]);

            }else if(GetDataPage('site')[props.index].contents.length == 2){
                newData.push(GetDataPage('site')[props.index].contents[0]);
                newData.push(GetDataPage('site')[props.index].contents[1]);
                newData.push(GetDataPage('site')[props.index].contents[0]);
                newData.push(GetDataPage('site')[props.index].contents[1]);

            }else if(GetDataPage('site')[props.index].contents.length == 3){
                newData.push(GetDataPage('site')[props.index].contents[0]);
                newData.push(GetDataPage('site')[props.index].contents[1]);
                newData.push(GetDataPage('site')[props.index].contents[2]);
                newData.push(GetDataPage('site')[props.index].contents[0]);
                newData.push(GetDataPage('site')[props.index].contents[1]);
                newData.push(GetDataPage('site')[props.index].contents[2]);
            }else {
                newData.push(GetDataPage('site')[props.index].contents);
            }
        }
        return newData;
    } 
    
    function AltPosition_prev(){
        var stream = document.querySelector('.div_list_img');
        var items = document.querySelectorAll('.show_img');
        
        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.show_img');
        
        AltEffect();
        countSlide = 0;
    }
    
    function AltPosition_next(){
        var stream = document.querySelector('.div_list_img');
        var items = document.querySelectorAll('.show_img');
        
        stream.appendChild(items[0]);
        items = document.querySelectorAll('.show_img');
        
        AltEffect();
        countSlide = 0;
    }

    function StartSlideShow(){
        var stream = document.querySelector('.div_list_img');
        var items = document.querySelectorAll('.show_img');
        
        stream.appendChild(items[0]);
        items = document.querySelectorAll('.show_img');
        
        AltEffect();
    }

    function AltEffect(){
        document.querySelectorAll('.title_slide').forEach((elem, index) => {
            elem.classList.add('title_close');
            elem.classList.remove('title');
            setTimeout(() => {
                elem.classList.remove('title_close');
            }, 1000);
            setTimeout(() => {
                elem.classList.add('title');
            }, 600);
        });
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'vimeo':
                let linkVimeo = video.split('/');
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://player.vimeo.com/video/" + linkVimeo[3] + "?autoplay=1&loop=1&title=0&byline=0&portrait=0&loop=1&controls=0" } frameBorder="0" allowFullScreen />
                        </div>
                    </div>
                )

            case 'youtube':
                let linkYoutube = video.split('=');
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://www.youtube.com/embed/" + linkYoutube[1] + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + linkYoutube[1] + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen />
                        </div>
                    </div>
                )
        }
    }

    useEffect(()=>{
        if(page == "index"){
            if(GetDataPage('site')[props.index].contents.length > 1){
                const altSlideShow = setInterval(() => {
                    countSlide++;
                    if(countSlide == qtdCount){
                        countSlide = 0;
                        StartSlideShow(); 
                    }
                }, 1000); 
                const play_ = document.getElementById("show_video");
                play_.play();
            }                
        }       
    }, []);

    return(
        <div className="Page_Home">
            {
                GetDataPage('site')[props.index].contents.length > 1 ? 
                <div className="show_btn container">
                    <div className="div_arrow" onClick={ ()=>{ AltPosition_prev() } }>
                        <SvgArrow_2 className="icons icons_left" color="rgb(255 181 0)" />
                    </div>
                    <div className="div_arrow" onClick={ ()=>{ AltPosition_next() } }>
                        <SvgArrow_2 className="icons" color="rgb(255 181 0)" />
                    </div>
                </div> : null
            }

            <div className="div_list_img">
                {
                    showData.map((elem, index)=>{
                        if(elem.type_video == 'image'){
                            return(
                                <div className="show_img" key={ index } style={ { backgroundImage: "url('" + elem.file + "')" } }>
                                    <div className="container">
                                        <div className="title_slide title" data-text={ elem.title }>
                                            { elem.title }
                                        </div>
                                        {
                                            elem.subtitle == "" ? null :
                                            <div className="title_slide title subtitle" data-text={ elem.subtitle }>
                                                { elem.subtitle }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }else {
                            return(
                                <div className="show_img" key={ index }>
                                    <div className="container">
                                        <div className="title_slide title" data-text={ elem.title }>
                                            { elem.title }
                                        </div>
                                        {
                                            elem.subtitle == "" ? null :
                                            <div className="title_slide title subtitle" data-text={ elem.subtitle }>
                                                { elem.subtitle }
                                            </div>
                                        }
                                    </div>
                                    <div className="video_background">
                                        <video id="show_video" className="video" loop={ true } autoPlay="autoplay" controls={ false } muted>
                                            <source src={ elem.video } type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}