import { useState, useEffect } from "react";

import './Menu.css';

import { Link } from "react-router-dom";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, SetListDataSingle } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

export default function Menu(props){

    const [ nameMenu, setNameMenu ] = useState(GetDataPage('name_menu'));
    const [ page, setPage ]         = useState(GetListPag('page'));
    
    const [ status, setStatus ] = useState(false);

    function OpenOrCloseMenu(value){
        setStatus(value);
        if(value == false){
            document.getElementById('menu_opt').classList.add('close_menu');
            setTimeout(() => {
                document.getElementById('menu_opt').classList.remove('close_menu');
                document.getElementById('menu_opt').classList.remove('open_menu');
            }, 1300);

        }else {
            document.getElementById('menu_opt').classList.add('open_menu');
        }
    }

    function OpenOptDiv(id){
        SetListPag('search', '');
        // if(id !='panel'){
        //     window.history.pushState("", "", "/");
        // };

        setStatus(false);
        document.getElementById('menu_opt').classList.add('close_menu');
        setTimeout(() => {
            document.getElementById('menu_opt').classList.remove('close_menu');
            document.getElementById('menu_opt').classList.remove('open_menu');
        }, 1300);

        SetListPag('page', id);
        
        const scrollInterval = setInterval(() => {            
            const element = document.getElementById(id);
            if (element) {
                clearInterval(scrollInterval);   
                let yOffset = -60; 
                if(window.innerWidth > 768){
                    yOffset += -10;
                } 
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }          
        }, 200);

        if(page !='panel'){
            const newData = [];
            GetDataPage('panel').map((elem, index)=>{
                elem.status = false;
                newData.push(elem);
            });
            SetListDataSingle('panel', newData);
        }
    }

    function OpenPopUp(value){
        SetModalData('Redirect', { "type": "open_link", "link": value })
        SetModalState('Redirect', true);
    }

    useEffect(()=>{
        RegisterListPag('page', setPage);

        window.addEventListener("resize",()=>{
            if(window.innerWidth >= 950){
                setStatus(false);
            }
        });
        
        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('div_menu').classList.add('div_menu_scroll');
            }else {
                document.getElementById('div_menu').classList.remove('div_menu_scroll');
            }
        });
    }, []);

    useEffect(()=>{
        setPage(GetListPag('page'));
    }, [page]);
    
    return(
        <div className="div_menu" id="div_menu">
            <div className="show_div_menu container">
                <Link to="/" className="div_logotipo" onClick={ ()=>{ OpenOptDiv('home') } }>
                    <img alt="logotipo" src="./assets/logo.png" className="logotipo" />
                </Link>

                <div className="">
                    <label className="buttons__burger">
                        <input type="checkbox" id="burger" onChange={ (e)=>{ OpenOrCloseMenu(!status) } } checked={ status } />
                        <span />
                        <span />
                        <span />
                    </label>
                    <div id="menu_opt" className="opt_menu">
                        {
                            Object.keys(nameMenu).map((elem, index)=>{
                                if(nameMenu[index].id == 8){
                                    return(
                                        <div className="name_menu" key={ index } onClick={ ()=>{ OpenPopUp(nameMenu[index].page); OpenOptDiv(nameMenu[index].page) } }>
                                            { nameMenu[index].name }
                                        </div>
                                    )

                                }else {
                                    if(nameMenu[index].id == 4 || nameMenu[index].id == 5){
                                        return(                                        
                                            <Link to={ "/" + nameMenu[index].page } className="name_menu" onClick={ ()=>{ OpenOptDiv(nameMenu[index].page) } } key={ index }>
                                                { nameMenu[index].name }
                                                {
                                                    nameMenu[index].page == page ? <div className="menu_active" /> : null
                                                }
                                            </Link>
                                        )
                                    }else {
                                        return(                                       
                                            <Link to="/" className="name_menu" onClick={ ()=>{ OpenOptDiv(nameMenu[index].page) } } key={ index }>
                                                { nameMenu[index].name }
                                                {
                                                    nameMenu[index].page == page ? <div className="menu_active" /> : null
                                                }
                                            </Link>
                                        )
                                    }
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}