import { useState, useEffect } from "react";

import './Home.css';

import { GetDataPage } from "interface/Data";

import Model_Home from "components/ModelPage/Home";
import Model_About from "components/ModelPage/About";
import Model_Client from "components/ModelPage/Client";
import Model_Product from "components/ModelPage/Product";

export default function Page_Home(props){

    const [ showData, setShowData ] = useState(GetDataPage('name_menu'));

    function ShowDataPage(value, index){
        switch (value) {
            case 1:
                return <Model_Home CkickPage={ props.CkickPage } setLoading={ props.setLoading } idPage={ value } index={ index } />;

            case 2:
                return <Model_About CkickPage={ props.CkickPage } setLoading={ props.setLoading } idPage={ value } index={ index } />;

            case 3:
                return <Model_Product CkickPage={ props.CkickPage } setLoading={ props.setLoading } idPage={ value } index={ index } />;

            case 6:
                return <Model_Client CkickPage={ props.CkickPage } setLoading={ props.setLoading } idPage={ value } index={ index } />;
        }
    }

    return(
        <>
            {
                Object.keys(showData).map((elem, index)=>{
                    if(showData[index].id != 4 && showData[index].id != 5 && showData[index].id != 8 && showData[index].id != 9){
                        return(
                            <div className="show_data_page" id={ showData[index].page } key={ index }>
                                { ShowDataPage(showData[index].id, index) }
                            </div>
                        )
                    }
                })
            }
        </>
    )
}